import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpInterceptorFn,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';



// @Injectable({
//   providedIn: 'root',
// })
export const TokenInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const authToken = localStorage.getItem('vendor_token') ? localStorage.getItem('vendor_token') : localStorage.getItem('token');
  const lang: any = localStorage.getItem('selected_language') ? localStorage.getItem('selected_language') : localStorage.setItem('selected_language', 'en');
  const offset = new Date().getTimezoneOffset().toString();
  let authReq: any;
  if (req.url.startsWith('http') || req.url.includes('assets')) {
    authReq = req.clone();
  } else {
    let newHeaders = req.headers
      .append('Authorization', `Bearer ${authToken}`)
      .append('Accept-Language', lang)
      .append('Timezone-Offset', offset);
    authReq = req.clone({
      headers: newHeaders,
      url: `${environment.apiUrl}${req.url}`,
    });
    //Vaibhav
    // authReq = req.clone({
    //   setHeaders: {
    //     Authorization: `Bearer ${authToken}`,
    //     "Accept-Language": lang ? lang : 'en',
    //   },
    //   url: `${environment.apiUrl}${req.url}`,
    // });
  }
  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status == 422) {
        localStorage.removeItem('vendor_token')
        router.navigate(['/vendor/login']);
      }
      // Return the error to the calling service
      return throwError(() => error);
    })
  );
};