import { Routes } from '@angular/router';
import { AuthGuard, authGuardafterLoggin } from './modules/auth/guard/auth.guard';

export const routes: Routes = [
    {
        path:'',redirectTo:'vendor',pathMatch:'full'
    },
    {
        path: 'vendor',
        canActivate:[authGuardafterLoggin],
        loadChildren: () =>
            import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
    },
    {
        path: '',
        canActivate:[AuthGuard],
        loadChildren: () =>
            import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
    },
    { path: '**', redirectTo: 'error/404' },
];
