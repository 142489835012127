import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


export const AuthGuard: CanActivateFn = (route, state) => {
  if(!localStorage.getItem('vendor_token')) {
    inject(Router).navigate(['/vendor/login'])
    return false;
  }
  return true;
};
export const authGuardafterLoggin: CanActivateFn = (route, state): Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {

  if (localStorage.getItem('vendor_token')) {
    if (route.routeConfig?.path == 'vendor' || route.routeConfig?.path == 'vendor/login') {
      inject(Router).navigate(['/dashboard'])
      return true;
    }
  }
  return true
};
